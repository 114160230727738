/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { PageContextProvider } from './src/context/pageContext';

export const wrapPageElement = ({ element, props: { pageContext} }) => {
  return <PageContextProvider value={pageContext}>{element}</PageContextProvider>
};

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("./../../../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-services-es-js": () => import("./../../../src/pages/services.es.js" /* webpackChunkName: "component---src-pages-services-es-js" */),
  "component---src-pages-team-en-js": () => import("./../../../src/pages/team.en.js" /* webpackChunkName: "component---src-pages-team-en-js" */),
  "component---src-pages-team-es-js": () => import("./../../../src/pages/team.es.js" /* webpackChunkName: "component---src-pages-team-es-js" */),
  "component---src-pages-thank-you-en-js": () => import("./../../../src/pages/thank-you.en.js" /* webpackChunkName: "component---src-pages-thank-you-en-js" */),
  "component---src-pages-thank-you-es-js": () => import("./../../../src/pages/thank-you.es.js" /* webpackChunkName: "component---src-pages-thank-you-es-js" */),
  "component---src-pages-work-en-js": () => import("./../../../src/pages/work.en.js" /* webpackChunkName: "component---src-pages-work-en-js" */),
  "component---src-pages-work-es-js": () => import("./../../../src/pages/work.es.js" /* webpackChunkName: "component---src-pages-work-es-js" */)
}


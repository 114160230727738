import React from 'react';
import { ThemeProvider } from 'styled-components';

import 'typeface-roboto';
import 'typeface-libre-baskerville';
import 'typeface-overpass';

const theme = {
  colors: {
    black: "#000000",
    grey: "#EBEAEB",
    blue: "linear-gradient(90deg, rgba(70,152,142,1) 0%, rgba(92,174,103,1) 49%, rgba(138,197,65,1) 100%)",
    white: "#ffffff",
    green: "#5DAF66"
  },
  fonts: {
    overpass: "Overpass",
    roboto: "Roboto",
    baskerville: "Libre Baskerville",
  }
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
